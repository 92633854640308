<template>
  <div class="page">
    <Header :loginState="loginState" />
    <div class="account-page">
      <left-menu />
      <div class="account-center">
        <div class="center-box account-information">
          <account-nav />
          <div class="title-box">           
            <div class="cfx"></div>
            <p>{{$t('accountInfor.accountDateTitle5')}}</p>
          </div>

          <!-- 修改密码 -->
          <div class="identity-box mailbox">
            <div class="from-box">
              <div class="input-list">
                <div class="icon-box">
                  <img class="pass-icon" src="../assets/images/register/pass-icon.png" alt="" />
                </div>
                <input @blur="blur(1)" v-model="originPassword" class="input-c" type="password" :placeholder="$t('accountInfor.modifyPassTips1')" />
                <p v-if="errShowList1.errShow" class="err-text">
                  <img src="../assets/images/register/dx-icon.png" alt="" />
                  <span>{{errShowList1.errContent}}</span>
                </p>
              </div>
              <div class="input-list">
                <div class="icon-box">
                  <img class="pass-icon" src="../assets/images/register/pass-icon.png" alt="" />
                </div>
                <input @blur="blur(2)" v-model="newPassword" class="input-c" type="password" :placeholder="$t('accountInfor.modifyPassTips2')" />
                <p v-if="errShowList2.errShow" class="err-text">
                  <img src="../assets/images/register/dx-icon.png" alt="" />
                  <span>{{errShowList2.errContent}}</span>
                </p>
              </div>
              <div class="input-list">
                <div class="icon-box">
                  <img class="pass-icon" src="../assets/images/register/pass-icon.png" alt="" />
                </div>
                <input @blur="blur(3)" v-model="confirmNewPassword" class="input-c" type="password" :placeholder="$t('accountInfor.modifyPassTips3')" />
                <p v-if="errShowList3.errShow" class="err-text">
                  <img src="../assets/images/register/dx-icon.png" alt="" />
                  <span>{{errShowList3.errContent}}</span>
                </p>
              </div>
              <input @click="modifyPassword" class="input-btn" type="button" :value="$t('perfectData.modify')" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 提示框组件 -->
    <Dialog ref="myConfirm"  :isShowConfirm="isShowConfirm" @userBehavior="userBehaviorFun" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import LeftMenu from '@/components/LeftMenu.vue';
import AccountNav from '@/components/AccountNav.vue';
import EmailInput from '@/components/EmailInput.vue';
import CodeInput from '@/components/CodeInput.vue';
import Dialog from '@/components/Dialog.vue';
import account from '@/api/account.js';
import { getCookie,delCookie } from '@/util/cookie';
import {Config, HEADER_USER_TOKEN} from '@/util/const.js';
import {REG_PASSWORD} from '@/util/const.js'
export default {
  name: "ModifyPassword",
  components: {
    Header,
    LeftMenu,
    AccountNav,
    EmailInput,
    CodeInput,
    Dialog
  },
  data() {
    return {
      loginState:2,
      isShowConfirm:false,
      findUserInfo:[],
      originPassword:'',
      newPassword:'',
      confirmNewPassword:'',
      errShowList1: {
        errShow:false,
        errContent:'',
      },
      errShowList2: {
        errShow:false,
        errContent:'',
      },
      errShowList3: {
        errShow:false,
        errContent:'',
      },
    }
  },
  created() {
      // 查询用户基本资料
    const userToken = getCookie(HEADER_USER_TOKEN);
    console.log(userToken);
    if(!userToken){
      this.$router.push({ path: '/login' })
    }else{
      account.findUserInfo().then((res)=>{
        if(res.data.success == 1){
              this.findUserInfo = res.data.value
          }else{
              console.log(res.data.message);
          }
      }).catch(err=>{
        console.log(err.message);
      })
    }
  },
  methods: {

    // 鼠标离开输入框校验不能为空
    blur(type){
      if(type==1){
        if(!this.originPassword){
          this.errShowList1.errShow = true;
          this.errShowList1.errContent = this.$i18n.t('loginRegister.checkPrompt10'); 
        }else{
          if(!REG_PASSWORD.test(this.originPassword)){
            this.errShowList1.errShow = true;
            this.errShowList1.errContent = this.$i18n.t('loginRegister.checkPrompt11'); 
          }else{
            this.errShowList1.errShow = false;
          }
        }      
      }
      if(type==2){
        if(!this.newPassword){
          this.errShowList2.errShow = true;
          this.errShowList2.errContent = this.$i18n.t('loginRegister.checkPrompt12'); 
        }else{
          if(!REG_PASSWORD.test(this.newPassword)){
            this.errShowList2.errShow = true;
            this.errShowList2.errContent = this.$i18n.t('loginRegister.checkPrompt13'); 
          }else{
            this.errShowList2.errShow = false;
          }
        }    
      }
      if(type==3){
        if(!this.confirmNewPassword){
          this.errShowList3.errShow = true;
          this.errShowList3.errContent = this.$i18n.t('loginRegister.checkPrompt15'); 
        }else{
          if(this.newPassword !== this.confirmNewPassword){
            this.errShowList3.errShow = true;
            this.errShowList3.errContent = this.$i18n.t('loginRegister.checkPrompt16'); 
          }else{
            this.errShowList3.errShow = false;
          }
        }           
      }
    },

    // 点击修改密码
    modifyPassword(){
      if(!this.originPassword){
        this.errShowList1.errShow = true;
        this.errShowList1.errContent = this.$i18n.t('loginRegister.checkPrompt10'); 
        return; 
      }
      if(!REG_PASSWORD.test(this.originPassword)){
        this.errShowList1.errShow = true;
        this.errShowList1.errContent = this.$i18n.t('loginRegister.checkPrompt11'); 
        return; 
      }else{
        this.errShowList1.errShow = false;
      }
      if(!this.newPassword){
        this.errShowList1.errShow = false;
        this.errShowList2.errShow = true;
        this.errShowList2.errContent = this.$i18n.t('loginRegister.checkPrompt12'); 
        return; 
      }
      if(!REG_PASSWORD.test(this.newPassword)){
        this.errShowList1.errShow = false;
        this.errShowList2.errShow = true;
        this.errShowList2.errContent = this.$i18n.t('loginRegister.checkPrompt13'); 
        return; 
      }else{
        this.errShowList2.errShow = false;
      }
      if(!this.confirmNewPassword){
        this.errShowList2.errShow = false;
        this.errShowList3.errShow = true;
        this.errShowList3.errContent = this.$i18n.t('loginRegister.checkPrompt15'); 
        return; 
      }
      if(!REG_PASSWORD.test(this.confirmNewPassword)){
        this.errShowList2.errShow = false;
        this.errShowList3.errShow = true;
        this.errShowList3.errContent = this.$i18n.t('loginRegister.checkPrompt14'); 
        return; 
      }else{
        this.errShowList3.errShow = false;
      }
      if(this.newPassword !== this.confirmNewPassword){
        this.errShowList2.errShow = false;
        this.errShowList3.errShow = true;
        this.errShowList3.errContent = this.$i18n.t('loginRegister.checkPrompt16'); 
        return; 
      }else{
        this.errShowList3.errShow = false;
      }
       let data = {
        newPwd: this.newPassword,
        origin: this.originPassword,
      }
      account.setPassword(data).then((res)=>{
          if(res.data.success == 1){
              const popTitle = this.$i18n.t('accountInfor.modifyPassTips4');//多语言传入的文字
              const cancel = this.$i18n.t('perfectData.cancel');
              const goLogin = this.$i18n.t('perfectData.goLogin');
              const title = this.$i18n.t('accountCenter.reminder');
              this.isShowConfirm = true
              this.$refs.myConfirm.show(popTitle, {
                  type: 'alert',
                  cancelText:cancel,
                  confirmText: goLogin,
                  titleText: title,
                  data: '我是外界传进来的数据'
              })
          }else{
            this.errShowList3.errShow = true;
            this.errShowList3.errContent = res.data.message;
          }
      }).catch(err=>{
        this.errShowList3.errShow = true;
        this.errShowList3.errContent = err.message;
        console.log(err.message);
      })
    },

    // 提示框组件传递取消和确定事件,根据对应的事件类型跳转或关闭提示框
    userBehaviorFun(type){
      console.log(type);
      const that = this;
       account.logout().then((res) => {
        if(res.data.success == 1){
          delCookie(HEADER_USER_TOKEN);
          Config.userToken = '';        
          this.$router.push({ path: '/login' })
        }else{
          console.log(err.data.message);
        }
      }).catch(err=>{
        console.log(err.message);
      }) 
      if(type === "clickCancel"){
        that.$router.push("./login");//去账户首页
        this.isShowConfirm = false   
      }else if(type === "clickConfirm"){
        that.$router.push("./login");
        this.isShowConfirm = false    
      }          
    }
  },
};
</script>
<style>
@import url("../assets/css/public.css");
</style>
